import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Paper, Typography, Grid, Divider, Button } from '@mui/material'
import Layout from '../layout'
import { styles } from '../utils'

const NotFoundPage = props => {
  const { notFoundImg } = useStaticQuery(graphql`
    query FourOFour {
      notFoundImg: file(name: { eq: "error-not-found" }) {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 80)
        }
      }
    }
  `)
  return (
    <Layout>
      <Paper elevation={8} sx={{ padding: 4 }}>
        <Typography variant='h1' sx={styles.singlePostTitle}>
          404 Page not found!
        </Typography>
        <Divider sx={{ width: '100%', mb: 16 }} />
        <Grid container>
          <Grid item xs={12} sx={styles.flexCenter}>
            <GatsbyImage image={getImage(notFoundImg)} alt={notFoundImg.name} loading='eager' style={{ width: '60%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} sx={styles.flexCenter}>
            <Typography align='center' variant='overline' component='div' sx={{ fontSize: '1.5rem', display: 'block', mt: 8 }}>
              What you are looking for doesn't exist.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={styles.flexCenter}>
            <Button variant='contained' color='primary' component={Link} to='/' sx={{ mt: 8, mb: 16 }}>
              Go back to Home
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  )
}

export default NotFoundPage
